import * as React from "react";
import Input from "src/components/AdminPanel/adminPanel/Input";
import Button from "src/components/shared/Button/Button";
import color from "src/utils/color";
import styled from "styled-components";
import {
  ContactUsFormValues,
  ContactUsRequestPayload,
} from "./landingPage.types";
import { validateEmail } from "./utils";
import { toast } from "react-toastify";
import Axios from "axios";

const publicApi = Axios.create({
  baseURL: `${process.env.REACT_APP_ADMIN_API_URL}/admin/`,
  timeout: 20000,
});

const Root = styled.section`
  color: ${color.TEXT_PRIMARY};

  > * + * {
    margin-top: 5rem;
  }
`;

const Header = styled.section`
  > * + * {
    margin-top: 1rem;
  }
`;

const Heading = styled.h2`
  font-weight: 700;
  font-size: 2.5rem;
  line-height: 3rem;
  color: ${color.PRIMARY};
  border-bottom: 1px solid ${color.PRIMARY};
  width: 23rem;
  margin: 0;
`;

const InfoSection = styled.p`
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.5rem;
  width: 29rem;
`;

export default function ContactUsScene() {
  return (
    <Root className="montserrat-landing-page-font">
      <Header>
        <Heading>Contact Us</Heading>
        <InfoSection>
          Talk to an expert to find out if UDM is the right fit for your company
        </InfoSection>
      </Header>
      <ContactUsFormContainer />
    </Root>
  );
}

const FormWrapper = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const ContactUsForm = styled.form`
  background-color: #6eb23f1a;
  padding: 1rem 5rem;
  width: 37.5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > * + * {
    margin-top: 1.25rem;
  }

  @media (max-width: 1240px) {
    width: 50%;
  }

  @media (max-width: 825px) {
    width: 100%;
    padding: 1rem 2.5rem;
  }
`;
const FormHeading = styled.h3`
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.5rem;
`;

const FormInput = styled(Input)`
  background-color: ${color.BACKGROUND};
`;
const FormLabel = styled.label`
  width: 75%;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 0.9rem;

  @media (max-width: 600px) {
    width: 100%;
  }
`;

type FormValuesProp = keyof ContactUsFormValues;

const INIT_FORM_VALUES: ContactUsFormValues = {
  firstName: "",
  lastName: "",
  email: "",
  companyName: "",
  message: "",
};
const MAX_MSG_CHAR_LENGTH = 500;

function ContactUsFormContainer() {
  const [formValues, setFormValues] = React.useState<ContactUsFormValues>(
    INIT_FORM_VALUES
  );
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const tooManyMessageChars =
    formValues.message.length > 0 &&
    formValues.message.length > MAX_MSG_CHAR_LENGTH;
  const invalidForm =
    !formValues.firstName.trim() ||
    !formValues.lastName.trim() ||
    !formValues.email.trim() ||
    !formValues.companyName.trim() ||
    tooManyMessageChars;

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    if (isSubmitting) return;
    const { firstName, lastName, email, companyName, message } = formValues;
    const trimmedEmail = email.trim();
    const isEmailValid = validateEmail(trimmedEmail);
    if (!isEmailValid) {
      toast.warn("Please input valid email address", {
        position: "top-center",
      });
      return;
    }
    if (tooManyMessageChars) {
      toast.warn(
        `Message cannot exceed ${MAX_MSG_CHAR_LENGTH} characters. Characters used: ${message.length}.`,
        { position: "top-center" }
      );
      return;
    }
    try {
      setIsSubmitting(true);
      const payload: ContactUsRequestPayload = {
        firstName: firstName.trim(),
        lastName: lastName.trim(),
        businessEmail: trimmedEmail,
        companyName: companyName.trim(),
        message: message.length ? message.trim() : null,
      };
      await publicApi.post("/contact-us", payload);
      setFormValues(INIT_FORM_VALUES);
      toast.success("E-mail successfully sent!", { position: "top-center" });
    } catch {
      toast.error("Error occurred while submitting! Please try again.", {
        position: "top-center",
      });
    } finally {
      setIsSubmitting(false);
    }
  }

  function handleInputChange(formProp: FormValuesProp) {
    return function(
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) {
      event.persist();
      setFormValues((current) => ({
        ...current,
        [formProp]: event.target.value,
      }));
    };
  }

  return (
    <FormWrapper>
      <ContactUsForm onSubmit={handleSubmit}>
        <FormHeading>Contact Us</FormHeading>
        <FormLabel>
          First name *
          <FormInput
            value={formValues.firstName}
            onChange={handleInputChange("firstName")}
            disabled={isSubmitting}
          />
        </FormLabel>
        <FormLabel>
          Last name *
          <FormInput
            value={formValues.lastName}
            onChange={handleInputChange("lastName")}
            disabled={isSubmitting}
          />
        </FormLabel>
        <FormLabel>
          Business Email *
          <FormInput
            value={formValues.email}
            onChange={handleInputChange("email")}
            disabled={isSubmitting}
          />
        </FormLabel>
        <FormLabel>
          Company name *
          <FormInput
            value={formValues.companyName}
            onChange={handleInputChange("companyName")}
            disabled={isSubmitting}
          />
        </FormLabel>
        <FormLabel>
          Message
          <MessageTextArea
            value={formValues.message}
            onChange={handleInputChange("message")}
            disabled={isSubmitting}
            maxLength={MAX_MSG_CHAR_LENGTH}
          />
          {formValues.message.length === MAX_MSG_CHAR_LENGTH && (
            <TooManyCharsError>
              You've reached maximum number of characters allowed (
              {MAX_MSG_CHAR_LENGTH}).
            </TooManyCharsError>
          )}
        </FormLabel>
        {isSubmitting ? (
          <p>Submitting</p>
        ) : (
          <Button
            filled
            style={{ marginBottom: "1rem" }}
            disabled={invalidForm}
          >
            Submit
          </Button>
        )}
      </ContactUsForm>
    </FormWrapper>
  );
}

const MessageTextArea = styled.textarea`
  height: 100px;
  resize: none;
  width: 100%;
  font-size: 15px;
  border-radius: 1rem;
  padding: 5px 10px;
  transform: translateY(3px);
  border: 1px solid ${color.PRIMARY};
  background-color: ${color.BACKGROUND};

  &:focus {
    outline: none;
    box-shadow: 0 0 4px 1px ${color.PRIMARY};
  }
`;

const TooManyCharsError = styled.p`
  margin-top: 0.25rem;
  color: ${color.NEGATIVE};
  font-weight: 250;
`;
