import { ActionButton } from "src/shared/components";
import color from "src/utils/color";
import styled from "styled-components";

export const FilterTabsWrapper = styled.div`
  display: flex;
  background-color: ${color.BACKGROUND_SECONDARY};
  padding: 0.25rem 0.75rem;
  column-gap: 1rem;
  border: 1px solid ${color.NEUTRAL_LIGHT};
  border-bottom: 0;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
`;

export const FilterTab = styled(ActionButton)<{ isActive: boolean }>`
  color: ${(props) => (props.isActive ? color.PRIMARY : "black")};
`;

export const FilterIndicator = styled.sub`
  color: ${color.PRIMARY};
  font: 0.5rem;
`;
