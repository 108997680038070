import * as React from "react";
import styled from "styled-components";
import color from "../../../utils/color";
import dropDownIcon from "../../../media/svg/arrowDropDown.svg";
import { ChangeEvent } from "react";

const Root = styled.div`
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
`;

const Select = styled.select`
  border: 1px solid ${color.PRIMARY};
  border-radius: 1rem;
  display: inline-block;
  width: 55px;
  line-height: 1.75rem;
  appearance: none;
  background-color: ${color.BACKGROUND_SECONDARY};
  background: transparent url("${dropDownIcon}") no-repeat right;
  font-weight: lighter;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  font-size: 0.75rem;
  padding-left: 0.85rem;
  cursor: pointer;
`;

type Props = {
  dataLength: number;
  perPage: number;
  perPageClickHandler(event: ChangeEvent<HTMLSelectElement>): void;
};

const PerPage: React.FC<Props> = ({
  dataLength,
  perPage,
  perPageClickHandler,
}) =>
  dataLength > 10 ? (
    <Root>
      Show
      <Select onChange={perPageClickHandler} value={perPage}>
        <option>10</option>
        <option>25</option>
        <option>50</option>
        <option>100</option>
      </Select>
      per page
    </Root>
  ) : null;

export default PerPage;
