import styled from "styled-components";
import color from "../../../utils/color";

const Input = styled.input<{ error?: boolean }>`
  width: calc(100% - 10px);
  font-size: 15px;
  border-radius: 1rem;
  padding: 5px 10px;
  transform: translateY(3px);
  border: ${({ error = false }) =>
    error ? `1px solid ${color.NEGATIVE}` : `1px solid ${color.PRIMARY}`};
  outline: none;

  &:focus {
    outline: none;
    box-shadow: ${({ error }) =>
      error ? null : `0 0 4px 1px ${color.PRIMARY}`};
  }
`;

export default Input;
