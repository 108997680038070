import {
  Announcement,
  AnnouncementPayload,
} from "src/scenes/EducationsAndAnnouncements/utils/announcements.types";
import {
  EducationalVideo,
  EEducationalVideoCategory,
} from "src/scenes/EducationsAndAnnouncements/utils/educationalVideos.types";
import { ActionType, createStandardAction } from "typesafe-actions";

export const educationsAndAnnouncementsActions = {
  announcements: {
    fetch: createStandardAction("@announcements/FETCH")(),
    populate: createStandardAction("@announcements/POPULATE")<
      Array<Announcement>
    >(),
    create: createStandardAction("@announcements/CREATE")<
      Partial<AnnouncementPayload>,
      () => void
    >(),
    add: createStandardAction("@announcements/ADD")<Announcement>(),
    delete: createStandardAction("@announcements/DELETE")<Announcement>(),
    remove: createStandardAction("@announcements/REMOVE")<Announcement>(),
    edit: createStandardAction("@announcements/EDIT")<
      { id: number; payload: Partial<AnnouncementPayload> },
      () => void
    >(),
    update: createStandardAction("@announcements/UPDATE")<{
      id: number;
      payload: Partial<AnnouncementPayload>;
    }>(),
  },
  educationalVideos: {
    fetch: createStandardAction("@educational_videos/FETCH")(),
    populate: createStandardAction("@educational_videos/POPULATE")<
      Array<EducationalVideo>
    >(),
    createVideo: createStandardAction("@educational_videos/CREATE")<
      {
        title: string;
        category: EEducationalVideoCategory;
        file: File;
        thumbnail: File;
        description: string;
        duration: number;
      },
      () => void
    >(),
    deleteVideo: createStandardAction("@educational_videos/DELETE")<
      EducationalVideo
    >(),
    removeVideo: createStandardAction("@educational_videos/REMOVE")<
      EducationalVideo
    >(),
    editVideo: createStandardAction("@educational_videos/EDIT")<
      {
        video: EducationalVideo;
        thumbnail: File | null;
      },
      () => void
    >(),
    updateVideo: createStandardAction("@educational_videos/UPDATE")<
      EducationalVideo
    >(),
  },
};

export type EducationsAndAnnouncementsActions = ActionType<
  typeof educationsAndAnnouncementsActions
>;
