import * as React from "react";
import styled from "styled-components";
import { Heading3 } from "./headings";
import color from "src/utils/color";

type Props = {
  isVisible: boolean;
  onClose: () => void;
  children: React.ReactNode;
  title: string;
};

export const Overlay: React.FC<Props> = ({
  isVisible,
  onClose,
  children,
  title,
}) => {
  if (!isVisible) return null;

  return (
    <Wrapper onClick={onClose}>
      <Content onClick={(e) => e.stopPropagation()}>
        <HeadWrapper>
          <Heading3>{title}</Heading3>
          <CloseButton onClick={onClose}>&times;</CloseButton>
        </HeadWrapper>
        {children}
      </Content>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000;
  backdrop-filter: blur(0.2rem);
`;

const Content = styled.div`
  background-color: #fff;
  padding: 20px;
  width: 50%;
  max-width: 90%;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: relative;
  max-height: 75%;
  overflow-y: auto;
`;

const HeadWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 2rem;
  cursor: pointer;
  transition: color 125ms linear;

  &:hover {
    color: ${color.PRIMARY};
  }
`;
