import * as React from "react";
import ProductScene from "./scenes/Product/ProductScene";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import LoginPageScene from "./scenes/LoginPage/LoginPageScene";
import NavBarContainer from "./containers/NavBarContainer/NavBarContainer";
import userManager from "./ducks/authentication/userManager";
import { setAccessToken } from "./utils/api";
import DashboardScene from "./scenes/Dashboard/DashboardScene";
import CatalogMenuScene from "./scenes/Menu/CatalogMenuScene";
import MenuScene from "./scenes/Menu/MenuScene";
import * as Loadable from "react-loadable";
import { authActions } from "./ducks/authentication/actions";
import { AuthState } from "./ducks/authentication/reducers";
import { connect } from "react-redux";
import LandingPageScene from "./scenes/LandingPage/LandingPageScene";
import { RootState } from "./ducks/store";
import { getAuthState } from "./ducks/authentication/selectors";
import AdminPanelSkeleton from "./components/Skeletons/AdminPanelSkeleton";
import CatalogScene from "./scenes/Catalog/CatalogScene";
import RevenuePredictionScene from "./scenes/DairyRevenuePrediction/RevenuePredictionScene";
import ViewMailNotificationsScene from "./scenes/ViewMailNotifications/ViewMailNotificationsScene";
import UdmApiSubscriptionScene from "./scenes/LandingPage/UdmApiSubscriptionScene";
import ContactUsScene from "./scenes/LandingPage/ContactUsScene";
import AboutUsScene from "./scenes/LandingPage/AboutUsScene";
import callback from "./callback";
import { Container, ContainerWrapper, Content, Root } from "./router/layout";
import PublicRoute from "./router/PublicRoute";
import PrivateRoute from "./router/PrivateRoute";
import NotFound from "./router/NotFound";
import EduAndAnnSkeleton from "./scenes/EducationsAndAnnouncements/EduAndAnnSkeleton";

const AdminPanel = Loadable({
  loader: () =>
    import("./scenes/ProfileAdministration/ProfileAdministrationScene"),
  loading: AdminPanelSkeleton,
  delay: 200,
  timeout: 10000,
});

const EduAndAnn = Loadable({
  loader: () =>
    import("./scenes/EducationsAndAnnouncements/EduAndAnnSceneContainer"),
  loading: EduAndAnnSkeleton,
  delay: 200,
  timeout: 10000,
});

type Props = typeof mapDispatchToProps & ReturnType<typeof mapStateToProps>;

class App extends React.Component<Props> {
  public componentDidMount() {
    userManager.getUser().then((user) => {
      if (user) {
        setAccessToken(user.token_type, user.access_token);
        this.props.getAuthState();
        this.props.setExpireAt(user.expires_at);
      }
    });
  }

  public render() {
    const isAuthenticated = !(
      this.props.auth === AuthState.initialization ||
      this.props.auth === AuthState.guest
    );

    return (
      <BrowserRouter>
        <Switch>
          <Route
            path="/"
            exact
            render={(props) =>
              isAuthenticated ? (
                <Root>
                  <NavBarContainer isLoggedIn={isAuthenticated} />
                  <ContainerWrapper>
                    <Container>
                      <Content>
                        <DashboardScene {...props} />
                      </Content>
                    </Container>
                  </ContainerWrapper>
                </Root>
              ) : (
                <>
                  <NavBarContainer isLoggedIn={isAuthenticated} />
                  <LandingPageScene {...props} />
                </>
              )
            }
          />
          <PublicRoute
            path="/udm-api-subscription"
            component={UdmApiSubscriptionScene}
            isAuthenticated={isAuthenticated}
          />
          <PublicRoute
            path="/contact-us"
            component={ContactUsScene}
            isAuthenticated={isAuthenticated}
          />
          <PublicRoute
            path="/about-us"
            component={AboutUsScene}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            path="/product/:id"
            component={ProductScene}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            path="/menu/catalog"
            component={CatalogMenuScene}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            path="/login"
            component={LoginPageScene}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            path="/admin"
            component={AdminPanel}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            path="/catalog"
            component={CatalogScene}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            path="/menu/:id"
            component={MenuScene}
            isAuthenticated={isAuthenticated}
            exact
          />
          <PrivateRoute
            path="/drp"
            component={RevenuePredictionScene}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            path="/view-mail-notifications"
            component={ViewMailNotificationsScene}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            path="/educations-and-announcements"
            component={EduAndAnn}
            isAuthenticated={isAuthenticated}
          />
          <Route path="/callback/:type" component={callback} />
          <Route component={NotFound} />
        </Switch>
      </BrowserRouter>
    );
  }
}

const mapDispatchToProps = {
  getAuthState: authActions.get.authState,
  setExpireAt: authActions.set.expiresAt,
};

const mapStateToProps = (state: RootState) => ({ auth: getAuthState(state) });

export default connect(mapStateToProps, mapDispatchToProps)(App);
