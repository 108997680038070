import * as React from "react";
import { connect } from "react-redux";
import { catalogActions } from "../../../ducks/catalog/actions";
import {
  getCatalogFilterSearch,
  getCatalogFilterSortOrder,
  getFilteredCatalogCountriesList,
  getCountryId,
} from "src/ducks/catalog/selectors";
import { SortOrder } from "../../../utils/enums";
import { RootState } from "../../../ducks/store";
import CatalogSelectModal from "../../../components/shared/Modal/CatalogSelectModal";

type OwnProps = {
  onClose: () => void;
  isTranslated: boolean;
};

type Props = OwnProps &
  typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps>;

const CountriesCatalogContainer: React.FC<Props> = ({
  getCounties,
  addCountry,
  removeAllCounties,
  removeCountry,
  setSortOrder,
  sortOrder,
  setSearch,
  search,
  filteredList,
  selectedList,
  onClose,
}) => {
  React.useEffect(() => {
    getCounties();
  }, []);

  const onListItemClick = (id: number) => () => {
    if (selectedList.includes(id)) {
      removeCountry(id);
    } else {
      addCountry(id);
    }
  };

  const onClearClick = () => {
    removeAllCounties();
  };

  const onSortClick = () => {
    if (sortOrder === SortOrder.DESCENDING) {
      setSortOrder(SortOrder.ASCENDING);
    } else {
      setSortOrder(SortOrder.DESCENDING);
    }
  };

  const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  return (
    <CatalogSelectModal
      name="Countries"
      onClose={onClose}
      onListItemClick={onListItemClick}
      list={filteredList}
      onClearSelectClick={onClearClick}
      sortOrder={sortOrder}
      onSortClick={onSortClick}
      search={search}
      onSearchChange={onSearchChange}
    />
  );
};

const mapStateToProps = (state: RootState, props: OwnProps) => ({
  filteredList: getFilteredCatalogCountriesList(state, props),
  selectedList: getCountryId(state),
  sortOrder: getCatalogFilterSortOrder(state),
  search: getCatalogFilterSearch(state),
});

const mapDispatchToProps = {
  getCounties: catalogActions.getCounties,
  addCountry: catalogActions.filter.addCountry,
  removeCountry: catalogActions.filter.removeCountry,
  removeAllCounties: catalogActions.filter.removeAllCounties,
  setSortOrder: catalogActions.filterUtils.setSortOrder,
  setSearch: catalogActions.filterUtils.setSearch,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CountriesCatalogContainer);
