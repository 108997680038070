export enum EEducationalVideoRole {
  None = "None",
  Administrator = "Administrator",
  Moderator = "Moderator",
  Producer = "Producer",
  Agent = "Agent",
  Member = "Member",
}

export enum EEducationalVideoCategory {
  SetupAndWeb = "SetupAndWeb",
  Excel = "Excel",
  DataTransformation = "DataTransformation",
  Api = "Api",
  Misc = "Misc",
}

export type ActiveCategory = keyof typeof EEducationalVideoCategory | null;

export type EducationalVideo = {
  id: string;
  fileName: string;
  filePath: string;
  uploadUri: string;
  created: string;
  createdBy: string;
  roles: Array<EEducationalVideoRole>;
  title: string;
  ordinal: number;
  category: EEducationalVideoCategory;
  timesPlayed: number;
  description: string;
  duration: number;
  thumbnailUri: string;
  videoDate: string;
};

export type EducationalVideoAttachment = {
  id: string;
  videoId: string;
  fileName: string;
  filePath: string;
  uploadUri: string;
  completed: boolean;
  created: string;
  deleted: boolean;
};

export type EducationalVideoThumbnail = {
  id: string;
  videoId: string;
  fileName: string;
  filePath: string;
  uploadUri: string;
  completed: boolean;
  created: string;
};

export type CreateEducationalVideoPayload = {
  title: string | null;
  duration: number;
  description: string | null;
  roles: Array<EEducationalVideoRole>;
  videoDate: string;
  category: EEducationalVideoCategory;
};

export type VideosDisplayMode = "Grid" | "List";

export type VideosViewType = VideosDisplayMode | "Summary";
