import color from "src/utils/color";
import styled from "styled-components";

export const Button = styled.button<{
  variant?: "primary" | "secondary" | "danger";
}>`
  cursor: pointer;
  padding: 0.5rem 1rem;
  color: ${({ variant }) => {
    switch (variant) {
      case "danger":
      case "primary":
        return "white";
      case "secondary":
        return color.NEUTRAL_DARK;
      default:
        return color.TEXT_PRIMARY;
    }
  }};
  background-color: ${({ variant }) => {
    switch (variant) {
      case "primary":
        return color.PRIMARY;
      case "secondary":
        return color.BACKGROUND;
      case "danger":
        return color.NEGATIVE;
      default:
        return color.BACKGROUND;
    }
  }};
  border: ${({ variant }) => {
    switch (variant) {
      case "primary":
        return `1px solid ${color.PRIMARY}`;
      case "secondary":
        return `1px solid ${color.NEUTRAL_DARK}`;
      default:
        return "none";
    }
  }};
  border-radius: 2rem;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.35);
  transition: transform 0.1s linear;
  text-transform: ${({ variant }) =>
    variant === "danger" ? "uppercase" : "none"};

  &:hover {
    transform: translateY(0.075rem);
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
    transform: none;
  }
`;

export const IconButton = styled.button`
  font-size: 1.25rem;
  padding: 0.25rem;
  border: none;
  background-color: transparent;
  display: flex;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.125s linear;

  &:hover {
    background-color: rgba(110, 178, 63, 0.175);
  }
`;

export const ActionButton = styled.button`
  color: ${color.PRIMARY};
  border: none;
  background-color: transparent;
  font-size: 0.95rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  column-gap: 0.125rem;
  padding: 0;

  &:hover {
    text-decoration: underline;
  }
`;
