import styled from "styled-components";
import color from "../../../../utils/color";

const PaginationBox = styled.div`
  height: 35px;
  text-align: center;
  line-height: 35px;
  color: white;
  font-size: 14px;
  width: 65px;
  font-weight: 600;
  background-color: ${color.PRIMARY};
  border-radius: 1.5rem;
  margin-right: 5px;
  cursor: pointer;
  padding: 0 0.5rem;
`;

export default PaginationBox;
