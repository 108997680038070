import { useState } from "react";

/**
 * Custom hook for handling async UI.
 * e.g. Displaying spinner (loader) while async request is executing.
 * @param initialState spinner initial state
 * @returns Spinner controls
 */
export const useSpinner = (initialState = false) => {
  const [isSpinning, setIsSpinning] = useState(initialState);

  const startSpin = () => setIsSpinning(true);
  const endSpin = () => setIsSpinning(false);

  return { isSpinning, startSpin, endSpin };
};

export type UseSpinner = ReturnType<typeof useSpinner>;
