import styled from "styled-components";

export const Heading3 = styled.h3`
  margin: 0;
  padding: 0.5rem 0;
`;

export const Heading4 = styled.h4`
  margin: 0;
  padding: 0.35rem 0;
`;
