import styled from "styled-components";
import palette from "../../../utils/color";

type Props = {
  width?: number;
  height?: number;
  color?: string;
  backgroundColor?: string;
  fontSize?: number;
  filled?: boolean;
};

const Button = styled.button<Props>`
  width: ${({ width = 120 }) => `${width}px`};
  height: ${({ height = 30 }) => `${height}px`};
  color: ${({
    color = palette.PRIMARY,
    filled = false,
    backgroundColor = palette.BACKGROUND,
  }) => (filled ? backgroundColor : color)};
  background-color: ${({
    backgroundColor = palette.BACKGROUND,
    filled = false,
    color = palette.PRIMARY,
  }) => (filled ? color : backgroundColor)};
  border-radius: 28px;
  font-size: ${({ height = 30, fontSize }) =>
    fontSize ? fontSize : height / 2}px;
  cursor: pointer;
  font-weight: 500;
  border: 1px solid ${({ color = palette.PRIMARY }) => color}};
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.35);

  &:hover {
    box-shadow: 2px 2px 2px rgba(128, 128, 128, 0.5);
    transform: perspective(1px) translateZ(0);
    transition-duration: 0.3s;
    transition-property: box-shadow;
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    cursor: not-allowed;
    border: 1px solid ${palette.NEUTRAL};
    color: ${palette.NEUTRAL};
  }
`;

export default Button;
