import * as React from "react";
import Button from "src/components/shared/Button/Button";
import styled from "styled-components";
import Logo from "../media/svg/udm_logo.svg";
import { Link, withRouter, RouteComponentProps } from "react-router-dom";

type Props = RouteComponentProps;

function NotFound({ history: routerHistory }: Props) {
  function goBack() {
    routerHistory.goBack();
  }

  return (
    <Root>
      <Link to="/">
        <LogoImg src={Logo} alt="Understanding Dairy Markets" />
      </Link>
      <h1>404 - Page Not Found</h1>
      <ButtonsWrapper>
        <Button onClick={goBack}>Back</Button>
        <Link to="/">
          <Button filled>Home</Button>
        </Link>
      </ButtonsWrapper>
    </Root>
  );
}

export default withRouter(NotFound);

const Root = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  row-gap: 1rem;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  column-gap: 1rem;
`;

const LogoImg = styled.img`
  height: 5rem;
`;
