import * as React from "react";
import * as oidc from "oidc-client";
import { RouteComponentProps, withRouter } from "react-router";
import styled from "styled-components";
import color from "./utils/color";
import { authActions } from "./ducks/authentication/actions";
import { connect } from "react-redux";
import userManager from "./ducks/authentication/userManager";
import { setAccessToken } from "./utils/api";

type Props = RouteComponentProps<{ type: string }> & typeof mapDispatchToProps;

const Root = styled.div`
  background-color: ${color.BACKGROUND_SECONDARY};
`;

const NavBar = styled.div`
  box-shadow: 0 3px 6px ${color.NEUTRAL};
  background-color: ${color.BACKGROUND};
  width: 100%;
  text-align: center;
  margin-bottom: 6px;
  height: 36px;
`;

const Container = styled.div`
  width: 100%;
  margin: auto;
  max-width: 1400px;
  min-height: 100vh;
  background-color: ${color.BACKGROUND};
`;

const callback: React.FC<Props> = ({
  match,
  history,
  getAuthState,
  setExpireAt,
}) => {
  switch (match.params.type) {
    case "login":
      new oidc.UserManager({})
        .signinRedirectCallback()
        .then(() => {
          userManager
            .getUser()
            .then((user) => {
              if (user) {
                setAccessToken(user.token_type, user.access_token);
                getAuthState();
                setExpireAt(user.expires_at);
              }
            })
            .finally(() => {
              history.push("/");
            });
        })
        .catch((e: any) => {
          console.error(e);
        });
      break;
    case "refresh":
      new oidc.UserManager({}).signinSilentCallback().catch((e: any) => {
        console.error(e);
      });
      break;
    case "logout":
      new oidc.UserManager({})
        .signoutRedirectCallback()
        .then(() => {
          history.push("/");
        })
        .catch((e: any) => {
          console.error(e);
        });
      break;
    default:
      console.error("no callback case");
      history.push("/");
  }

  return (
    <Root>
      <NavBar />
      <Container>
        <div>loading...</div>
      </Container>
    </Root>
  );
};

const mapDispatchToProps = {
  getAuthState: authActions.get.authState,
  setExpireAt: authActions.set.expiresAt,
};

export default withRouter(connect(null, mapDispatchToProps)(callback));
