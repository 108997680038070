import Axios from "axios";
import {
  Announcement,
  AnnouncementPayload,
} from "src/scenes/EducationsAndAnnouncements/utils/announcements.types";
import {
  CreateEducationalVideoPayload,
  EducationalVideo,
} from "src/scenes/EducationsAndAnnouncements/utils/educationalVideos.types";

const api = Axios.create({
  baseURL: `${process.env.REACT_APP_ADMIN_API_URL}/admin/`,
  timeout: 20000,
});

const adminAPI = {
  fetchSubscriptions: () =>
    api.get("/notification/view/subscription", { params: { active: true } }),
  fetchAllViews: () => api.get("/notification/view"),
  subscribeToView: (viewName: string) =>
    api.post("/notification/view/subscription", { viewName }),
  unsubscribeToView: (viewName: string) =>
    api.delete(`/notification/view/subscription/${viewName}`),
  // Announcements
  fetchAnnouncements: async () => await api.get("/education/announcement/web"),
  createAnnouncement: async (payload: Partial<AnnouncementPayload>) =>
    await api.post("/education/announcement", payload),
  deleteAnnouncement: async (announcement: Announcement) =>
    await api.delete(`/education/announcement/${announcement.id}`),
  editAnnouncement: async (params: {
    id: number;
    payload: Partial<AnnouncementPayload>;
  }) => await api.put(`/education/announcement/${params.id}`, params.payload),
  // Educational Videos
  fetchEducationalVideos: async () => await api.get("/education/video"),
  createEducationalVideo: async (params: {
    fileName: string;
    payload: CreateEducationalVideoPayload;
  }) =>
    await api.post(
      `/education/video/upload/${params.fileName}/uri`,
      params.payload
    ),
  completeUploadEducationalVideo: async (id: string) =>
    await api.put(`/education/video/${id}/upload/complete`),
  createEducationalVideoThumbnail: async (params: {
    videoId: string;
    fileName: string;
  }) =>
    await api.post(
      `/education/video/${params.videoId}/thumbnail/upload/${params.fileName}/uri`
    ),
  completeUploadEducationalVideoThumbnail: async (id: string) =>
    await api.put(`/education/video/thumbnail/${id}/upload/complete`),
  deleteEducationalVideo: async (video: EducationalVideo) =>
    await api.delete(`/education/video/${video.id}`),
  editEducationalVideo: async (payload: EducationalVideo) =>
    await api.put("/education/video", payload),
  getEducationVideoThumbnail: async (videoId: string) =>
    await api.get(`/education/video/${videoId}/thumbnail`),
  deleteEducationVideoThumbnail: async (guid: string) =>
    await api.delete(`/education/video/thumbnail/${guid}`),
};

export default adminAPI;
