import color from "src/utils/color";
import styled from "styled-components";

export const Root = styled.div`
  background-color: ${color.BACKGROUND_SECONDARY};
`;

export const ContainerWrapper = styled.div`
  padding-left: 0;
`;

export const Container = styled.div`
  width: 100%;
  margin: 0 auto 0 auto;
  max-width: 1400px;
  min-height: 100vh;
  background-color: ${color.BACKGROUND};
`;

export const Content = styled.div`
  width: 100%;
  margin: 0 auto;
  padding-top: 90px;
  padding-bottom: 40px;
  max-width: 1100px;
`;
