import * as React from "react";
import { CustomRouteProps } from "./router.types";
import { Redirect, Route } from "react-router-dom";
import NavBarContainer from "src/containers/NavBarContainer/NavBarContainer";
import styled from "styled-components";

export default function PublicRoute({
  component: Component,
  isAuthenticated,
  ...rest
}: CustomRouteProps) {
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          <Redirect to="/" />
        ) : (
          <>
            <NavBarContainer isLoggedIn={isAuthenticated} />
            <LandingPageScenesWrapper>
              <Component {...props} />
            </LandingPageScenesWrapper>
          </>
        )
      }
    />
  );
}

const LandingPageScenesWrapper = styled.div`
  padding: 10rem 5rem 2.5rem;

  @media (max-width: 550px) {
    padding: 5rem 2.5rem 1.25rem;
  }
`;
