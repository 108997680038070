import * as React from "react";
import { Redirect } from "react-router";

class DashboardScene extends React.Component {
  public render() {
    return (
      <>
        <Redirect to="/educations-and-announcements" />
      </>
    );
  }
}

export default DashboardScene;
