import { useState } from "react";

/**
 * Custom hook for controlling overlays.
 * Can be used for pop-ups, dialogs, modals, etc...
 * @param initialState overlay initial state (open/closed)
 * @returns Overlay controls
 */
export const useOverlay = (initialState = false) => {
  const [isOpen, setIsOpen] = useState(initialState);

  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);

  return { isOpen, handleOpen, handleClose };
};

export type UseOverlay = ReturnType<typeof useOverlay>;
