import * as React from "react";
import styled from "styled-components";
import CatalogMenu from "../../components/Catalog/CatalogMenu/CatalogMenu";
import { RouteComponentProps } from "react-router";
import ContentSearch from "../../components/AdminPanel/adminPanel/ContentSearch";
import color from "../../utils/color";
import CatalogSeriesScene from "./CatalogSeriesScene";
import CatalogReportsScene from "./CatalogReports/CatalogReportsScene";
import AngleRightIcon from "src/icons/AngleRightIcon";
import { Link as RouterLink } from "react-router-dom";
import CatalogReportsSeriesScene from "./CatalogReports/CatalogReportsSeriesScene";
import CatalogPublishersScene from "./CatalogPublishers/CatalogPublishersScene";
import CatalogPublishersReportsScene from "./CatalogPublishers/CatalogPublishersReportsScene";
import { catalogActions } from "../../ducks/catalog/actions";
import { connect } from "react-redux";

const Root = styled.div`
  display: flex;
`;

const Content = styled.div`
  flex: 1 1 0%;
  display: flex;
  flex-direction: column;
`;

const ContentHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  border-bottom: ${color.NEUTRAL} solid 2px;
`;

const ContentTitle = styled.div`
  display: flex;

  h2 {
    margin: 0 0 10px 5px;
    font-weight: 400;
    color: ${color.TEXT_PRIMARY};

    svg {
      color: ${color.PRIMARY};
      margin-left: 5px;
      margin-right: 2px;
      transform: translateY(3px);
    }

    span:last-child {
      color: ${color.PRIMARY};
    }
  }
`;

const Link = styled(RouterLink)`
  text-decoration: none;
  color: ${color.TEXT_PRIMARY};
`;

const MetaContainer = styled.table`
  padding-bottom: 10px;
  width: 100%;
  border-bottom: ${color.NEUTRAL} solid 2px;
  margin-bottom: 15px;

  tr {
    padding-bottom: 15px;
  }

  tr:last-child {
    padding-bottom: 0;
  }
`;

const MetaTitle = styled.td`
  vertical-align: top;
  font-weight: bold;
`;

const MetaText = styled.td`
  width: 100%;
  padding-left: 10px;
`;

export type RouterState = {
  nested?: Array<{
    name: string;
    forName: string;
    description: string;
    id: number | string;
  }>;
  translated?: boolean;
};

type Props = RouteComponentProps<{}, {}, RouterState> &
  typeof mapDispatchToProps;

const CatalogScene: React.FC<Props> = ({ location, clearAllFilters }) => {
  const [search, setSearch] = React.useState("");
  const [selectedCatalog, setSelectedCatalog] = React.useState("Publishers");

  const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setSearch(event.target.value);

  const onCatalogChange = (name: string) => {
    setSelectedCatalog(name);
    clearAllFilters();
    setSearch("");
  };

  const CatalogView = (() => {
    switch (selectedCatalog) {
      case "Publishers": {
        if (location.state && location.state.nested) {
          return location.state.nested.length === 1
            ? CatalogPublishersReportsScene
            : CatalogReportsSeriesScene;
        } else {
          return CatalogPublishersScene;
        }
      }
      case "Reports":
        return location.state ? CatalogReportsSeriesScene : CatalogReportsScene;
      case "Series":
        return CatalogSeriesScene;
      default:
        return CatalogSeriesScene;
    }
  })();

  return (
    <Root>
      <CatalogMenu selected={selectedCatalog} onChange={onCatalogChange} />
      <Content>
        <ContentHeader>
          <ContentTitle>
            <h2>
              <Link to={{ state: undefined }}>{selectedCatalog}</Link>
              {location.state &&
                location.state.nested &&
                selectedCatalog !== "Series" &&
                location.state.nested.map(({ forName }, index, array) => (
                  <React.Fragment key={forName}>
                    <AngleRightIcon />
                    <span>
                      {index + 1 !== array.length ? (
                        <Link
                          to={{
                            state: {
                              nested: [...array].splice(0, index + 1),
                              translated: location.state.translated,
                            },
                          }}
                        >
                          {forName}
                        </Link>
                      ) : (
                        forName
                      )}
                    </span>
                  </React.Fragment>
                ))}
            </h2>
          </ContentTitle>
          <ContentSearch
            type="search"
            value={search}
            onChange={onSearchChange}
            placeholder="Search"
          />
        </ContentHeader>
        {location.state &&
          location.state.nested &&
          selectedCatalog !== "Series" && (
            <MetaContainer>
              <tbody>
                {location.state.nested.map(({ name, description }) => (
                  <tr key={name}>
                    <MetaTitle>{name}:</MetaTitle>
                    <MetaText>{description}</MetaText>
                  </tr>
                ))}
              </tbody>
            </MetaContainer>
          )}
        <CatalogView search={search} locationState={location.state} />
      </Content>
    </Root>
  );
};

const mapDispatchToProps = { clearAllFilters: catalogActions.filter.clearAll };

export default connect(null, mapDispatchToProps)(CatalogScene);
