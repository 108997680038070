import produce from "immer";
import { Announcement } from "src/scenes/EducationsAndAnnouncements/utils/announcements.types";
import { EducationalVideo } from "src/scenes/EducationsAndAnnouncements/utils/educationalVideos.types";
import {
  educationsAndAnnouncementsActions,
  EducationsAndAnnouncementsActions,
} from "./actions";
import { getType } from "typesafe-actions";

type EduAndAnn = {
  educationalVideos: Array<EducationalVideo>;
  announcements: Array<Announcement>;
};

const initialState: EduAndAnn = {
  announcements: [],
  educationalVideos: [],
};

export const educationsAndAnnouncementsReducer = produce<
  EduAndAnn,
  EducationsAndAnnouncementsActions
>((state, action) => {
  switch (action.type) {
    case getType(educationsAndAnnouncementsActions.announcements.populate): {
      const fetchedAnnouncements = action.payload;
      state.announcements = fetchedAnnouncements.sort(
        (a, b) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      );
      return;
    }
    case getType(educationsAndAnnouncementsActions.announcements.add): {
      const announcement = action.payload;
      state.announcements.push(announcement);
      return;
    }
    case getType(educationsAndAnnouncementsActions.announcements.remove): {
      const deletedAnnouncement = action.payload;
      const deletedAnnouncementIndex = state.announcements.findIndex(
        (a) => a.id === deletedAnnouncement.id
      );
      if (deletedAnnouncementIndex !== -1) {
        state.announcements.splice(deletedAnnouncementIndex, 1);
      }
      return;
    }
    case getType(educationsAndAnnouncementsActions.announcements.update): {
      const updatedAnnouncement = state.announcements.find(
        (a) => a.id === action.payload.id
      );
      if (updatedAnnouncement) {
        updatedAnnouncement.title = String(action.payload.payload.title);
        updatedAnnouncement.description = String(
          action.payload.payload.description
        );
      }
      return;
    }
    case getType(
      educationsAndAnnouncementsActions.educationalVideos.populate
    ): {
      const fetchedVideos = action.payload;
      state.educationalVideos = fetchedVideos.sort(
        (a, b) => new Date(b.created).getTime() - new Date(a.created).getTime()
      );
      return;
    }
    case getType(
      educationsAndAnnouncementsActions.educationalVideos.removeVideo
    ): {
      const deletedVideo = action.payload;
      const deletedVideoIndex = state.educationalVideos.findIndex(
        (ev) => ev.id === deletedVideo.id
      );
      if (deletedVideoIndex !== -1) {
        state.educationalVideos.splice(deletedVideoIndex, 1);
      }
      return;
    }
    case getType(
      educationsAndAnnouncementsActions.educationalVideos.updateVideo
    ): {
      const updatedVideo = state.educationalVideos.find(
        (ev) => ev.id === action.payload.id
      );
      if (updatedVideo) {
        updatedVideo.title = action.payload.title;
        updatedVideo.category = action.payload.category;
        updatedVideo.description = action.payload.description;
        updatedVideo.thumbnailUri = action.payload.thumbnailUri;
      }
      return;
    }
    default:
      return state;
  }
}, initialState);
