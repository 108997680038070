import * as React from "react";
import ClearFilterContainer from "../../../components/Catalog/styles/ClearFilterContainer/ClearFilterContainer";
import FilterContent from "../../../components/Catalog/styles/FilterContent/FilterContent";
import { RootState } from "../../../ducks/store";
import {
  getCatalogSort,
  getCategoryId,
  getCountryId,
  getMeasureId,
  getPublisherIds,
  getReportCode,
} from "../../../ducks/catalog/selectors";
import { catalogActions } from "../../../ducks/catalog/actions";
import { connect } from "react-redux";
import CatalogSortContainer from "../../../containers/CatalogSortContainer/CatalogSortContainer";
import LangPicker from "../../../components/shared/LangPicker/LangPicker";
import PaginationWrapper from "../../../components/AdminPanel/adminPanel/PaginationWrapper";
import PerPage from "../../../components/shared/Pagination/perPage";
import PaginationContainer from "../../../components/Catalog/styles/PaginationContainer/PaginationContainer";
import PaginationBox from "../../../components/Catalog/styles/PaginationBox/PaginationBox";
import PublishersCatalogContainer from "../../../containers/catalog/PublishersCatalogContainer/PublishersCatalogContainer";
import CategoriesCatalogContainer from "../../../containers/catalog/CategoriesCatalogContainer/CategoriesCatalogContainer";
import MeasuresCatalogContainer from "../../../containers/catalog/MeasuresCatalogContainer/MeasuresCatalogContainer";
import catalogAPI from "../../../services/catalogServices";
import SeriesLink from "../../../components/Catalog/styles/SeriesLink/SeriesLink";
import { Link } from "react-router-dom";
import { RouterState } from "../CatalogScene";
import CountriesCatalogContainer from "../../../containers/catalog/CountriesCatalogContainer/CountriesCatalogContainer";
import { useOverlay } from "src/shared/hooks";
import { FaFilter } from "react-icons/fa";
import FilterButton from "src/components/Catalog/styles/FilterContent/FilterButton";
import { Overlay } from "src/shared/components/overlay";
import {
  FilterIndicator,
  FilterTab,
  FilterTabsWrapper,
} from "src/components/Catalog/styles/FilterContent/FilterTabs";
import ModalContentWrapper from "src/components/Catalog/styles/FilterContent/ModalContentWrapper";

enum ModalView {
  none,
  publishers,
  countries,
  categories,
  measures,
}

type OwnProps = {
  search: string;
  locationState: RouterState;
};

type Props = OwnProps &
  ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

const CatalogReportsScene: React.FC<Props> = ({
  search,
  sort,
  publisherIdList,
  countryList,
  categoryList,
  measureList,
  clearAll,
  resetFilterSearch,
  locationState,
}) => {
  const [translated, setTranslation] = React.useState(
    locationState && locationState.translated ? locationState.translated : false
  );
  const [pageSize, setPageSize] = React.useState(25);
  const [page, setPage] = React.useState(1);
  const [seriesList, setList] = React.useState<
    Array<{ id: string; name: string }>
  >([]);
  const [hasNext, setHasNext] = React.useState(false);
  const [modalView, setModalView] = React.useState(ModalView.none);
  const filtersOverlay = useOverlay();

  const switchClick = () => setTranslation(!translated);

  const onPageSizeChange = (event: React.ChangeEvent<HTMLSelectElement>) =>
    setPageSize(Number(event.target.value));

  const setNextPage = () => setPage(page - 1);
  const setPreviousPage = () => setPage(page + 1);

  const onFilterClick = (filter: ModalView) => () => {
    resetFilterSearch();
    setModalView(filter);
  };

  const getModalView = () => {
    const modalProps = {
      onClose: () => {
        filtersOverlay.handleClose();
        setModalView(ModalView.none);
      },
      isTranslated: translated,
    };

    switch (modalView) {
      case ModalView.publishers:
        return <PublishersCatalogContainer {...modalProps} />;
      case ModalView.countries:
        return <CountriesCatalogContainer {...modalProps} />;
      case ModalView.categories:
        return <CategoriesCatalogContainer {...modalProps} />;
      case ModalView.measures:
        return <MeasuresCatalogContainer {...modalProps} />;
      default:
        return null;
    }
  };

  const clearAllFilters = () => {
    clearAll();
  };

  React.useEffect(() => {
    if (modalView === ModalView.none) {
      (async () => {
        try {
          const response = await catalogAPI.searchReports(
            {
              pageSize,
              translated,
              page: page - 1,
              contains: search.length >= 3 ? search : "",
              startsWith: sort === "All" || sort === "Recent" ? "" : sort,
            },
            {
              publisherId: publisherIdList,
              countryId: countryList,
              categoryId: categoryList,
              measureId: measureList,
            }
          );
          setList(response.data);
          setHasNext(response.hasNext);
        } catch (err) {
          console.error("Error: catalogAPI.searchReports failed!", err);
        }
      })();
    }
  }, [
    translated,
    search,
    pageSize,
    page,
    sort,
    modalView,
    publisherIdList,
    countryList,
    categoryList,
    measureList,
  ]);

  const prevValues =
    locationState && locationState.nested ? locationState.nested : [];

  return (
    <>
      <FilterContent>
        <FilterButton
          onClick={() => {
            onFilterClick(ModalView.countries)();
            filtersOverlay.handleOpen();
          }}
        >
          <FaFilter />
          Filters
        </FilterButton>
        <ClearFilterContainer onClick={clearAllFilters}>
          Clear all Filters
        </ClearFilterContainer>
      </FilterContent>
      <div style={{ display: "flex" }}>
        <CatalogSortContainer />
        <LangPicker active={translated} onClick={switchClick} />
      </div>
      {seriesList.map(({ id, name }) => (
        <SeriesLink key={id}>
          <Link
            to={{
              state: {
                nested: [
                  ...prevValues,
                  { name: "Report", forName: "Series", description: name, id },
                ],
                translated,
              },
            }}
          >
            {name}
          </Link>
        </SeriesLink>
      ))}
      <PaginationWrapper>
        {/*TODO: use dataLength from backend*/}
        <PerPage
          dataLength={100}
          perPage={pageSize}
          perPageClickHandler={onPageSizeChange}
        />
        <PaginationContainer>
          {page > 1 && (
            <PaginationBox onClick={setNextPage}>Previous</PaginationBox>
          )}
          {hasNext && (
            <PaginationBox onClick={setPreviousPage}>Next</PaginationBox>
          )}
        </PaginationContainer>
      </PaginationWrapper>
      <Overlay
        isVisible={filtersOverlay.isOpen}
        onClose={() => {
          filtersOverlay.handleClose();
          setModalView(ModalView.none);
        }}
        title="Catalog Filters"
      >
        <>
          <FilterTabsWrapper>
            <FilterTab
              isActive={modalView === ModalView.publishers}
              onClick={onFilterClick(ModalView.publishers)}
            >
              Publishers
              {publisherIdList.length > 0 && (
                <FilterIndicator>({publisherIdList.length})</FilterIndicator>
              )}
            </FilterTab>
            <FilterTab
              isActive={modalView === ModalView.countries}
              onClick={onFilterClick(ModalView.countries)}
            >
              Countries
              {countryList.length > 0 && (
                <FilterIndicator>({countryList.length})</FilterIndicator>
              )}
            </FilterTab>
            <FilterTab
              isActive={modalView === ModalView.categories}
              onClick={onFilterClick(ModalView.categories)}
            >
              Categories
              {categoryList.length > 0 && (
                <FilterIndicator>({categoryList.length})</FilterIndicator>
              )}
            </FilterTab>
            <FilterTab
              isActive={modalView === ModalView.measures}
              onClick={onFilterClick(ModalView.measures)}
            >
              Measures
              {measureList.length > 0 && (
                <FilterIndicator>({measureList.length})</FilterIndicator>
              )}
            </FilterTab>
          </FilterTabsWrapper>
          <ModalContentWrapper>{getModalView()}</ModalContentWrapper>
        </>
      </Overlay>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  sort: getCatalogSort(state),
  publisherIdList: getPublisherIds(state),
  reportsList: getReportCode(state),
  countryList: getCountryId(state),
  categoryList: getCategoryId(state),
  measureList: getMeasureId(state),
});

const mapDispatchToProps = {
  clearAll: catalogActions.filter.clearAll,
  resetFilterSearch: catalogActions.filterUtils.reset,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CatalogReportsScene);
