import * as React from "react";
import styled from "styled-components";
import color from "../../../utils/color";
import AngleRightIcon from "src/icons/AngleRightIcon";
import { Link as RouterLink } from "react-router-dom";

const Root = styled.div`
  width: 185px;
  display: flex;
  flex-direction: column;
`;

const Item = styled.div<{ isSelected: boolean }>`
  display: flex;
  align-items: center;
  font-weight: ${({ isSelected }) => (isSelected ? 600 : 400)};
  cursor: pointer;
  padding: 10px 0;

  svg {
    color: ${color.PRIMARY};
  }
`;

const Link = styled(RouterLink)`
  text-decoration: none;
  color: ${color.TEXT_PRIMARY};
`;

type Props = {
  selected: string;
  onChange: (name: string) => void;
};

const catalogList = [
  { name: "Publishers" },
  { name: "Reports" },
  { name: "Series" },
];

const CatalogMenu: React.FC<Props> = ({ selected, onChange }) => {
  const onMenuClick = (name: string) => () => {
    if (selected !== name) onChange(name);
  };

  return (
    <Root>
      {catalogList.map(({ name }) => (
        <Item
          isSelected={name === selected}
          key={name}
          onClick={onMenuClick(name)}
        >
          <AngleRightIcon />
          <Link to={{ state: undefined }}>{name}</Link>
        </Item>
      ))}
    </Root>
  );
};

export default CatalogMenu;
