import * as React from "react";
import styled from "styled-components";
import color from "../../../utils/color";
import dropDownIcon from "src/media/svg/arrowDropDown.svg";
import SelectOption from "./SelectOption";
import Scrollbars from "react-custom-scrollbars";

const SelectFieldWrapper = styled.div`
  display: inline-block;
  z-index: 10;
`;

const Select = styled.div<{
  width?: number;
  textColor: string;
  fontWeight: number;
}>`
  border: 1px solid ${color.PRIMARY};
  border-radius: 1.5rem;
  display: inline-block;
  position: relative;
  padding: 5px 20px 5px 15px;
  width: calc(${({ width = 185 }) => width}px - 20px);
  line-height: 27px;
  appearance: none;
  background: transparent url("${dropDownIcon}") no-repeat right;
  font-weight: ${({ fontWeight }) => fontWeight};
  font-size: 14px;
  cursor: pointer;
  color: ${({ textColor }) => textColor};
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25 );
`;

const SelectDropDown = styled.div<{ isOpened: boolean; width?: number }>`
  display: ${({ isOpened }) => (isOpened ? "block" : "none")};
  position: absolute;
  padding-left: 5px;
  border: 1px solid ${color.NEUTRAL_LIGHT};
  background-color: ${color.BACKGROUND};
  border-radius: 6px;
  width: ${({ width = 185 }) => width}px;
  z-index: 20;
  cursor: pointer;
`;

const Input = styled.input<{
  width?: number;
  textColor: string;
  fontWeight: number;
}>`
  font-family: "Open Sans", sans-serif;
  border: none;
  outline: none;
  font-size: 14px;
  font-weight: ${({ fontWeight }) => fontWeight};
  color: ${({ textColor }) => textColor};
  margin: 0 0 0 -1px;
  cursor: pointer;
  caret-color: transparent;
  width: calc(${({ width = 185 }) => width}px - 30px);

  &::placeholder {
    color: ${({ textColor }) => textColor};
  }
`;

type Props = {
  options: Array<{
    id: number | string;
    name: string;
  }>;
  value: string;
  width?: number;
  textColor?: string;
  fontWeight?: number;
  onChange: (value: string | number) => void;
};

type State = {
  isOpened: boolean;
  filter: string;
};

class SelectField extends React.Component<Props, State> {
  public state = {
    isOpened: false,
    filter: "",
  };

  public render() {
    const { filter, isOpened } = this.state;
    const {
      value,
      options,
      width,
      onChange,
      textColor = color.TEXT_PRIMARY,
      fontWeight = 400,
    } = this.props;

    const SelectOptions = options
      .filter((option) =>
        option.name.toLowerCase().includes(filter.toLowerCase())
      )
      .map((option) => (
        <SelectOption
          type="radio"
          key={option.id}
          name={option.name}
          checked={value === option.name}
          value={option.id}
          label={option.name}
          onClick={value !== option.name ? onChange : undefined}
        />
      ));

    return (
      <SelectFieldWrapper onMouseLeave={this.handleMouseLeave}>
        <Select
          onClick={this.handleSelectDropDown}
          width={width}
          textColor={textColor}
          fontWeight={fontWeight}
        >
          {!isOpened ? (
            value
          ) : (
            <Input
              type="text"
              textColor={textColor}
              fontWeight={fontWeight}
              placeholder={value}
              value={filter}
              onChange={this.onInputChange}
              width={width}
              autoFocus
            />
          )}
        </Select>
        <SelectDropDown isOpened={isOpened} width={width}>
          <Scrollbars
            hideTracksWhenNotNeeded={true}
            autoHeightMax={115}
            autoHeight={true}
          >
            <div onClick={this.handleSelectDropDown}>{SelectOptions}</div>
          </Scrollbars>
        </SelectDropDown>
      </SelectFieldWrapper>
    );
  }

  private onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ filter: event.target.value });
  };

  private handleSelectDropDown = () => {
    this.setState({ isOpened: !this.state.isOpened, filter: "" });
  };

  private handleMouseLeave = () => {
    this.setState({ isOpened: false, filter: "" });
  };
}

export default SelectField;
