import produce from "immer";
import { getType } from "typesafe-actions";
import { authActions, AuthActions } from "./actions";

export const UDM_AUTH_STATE_LS_ID = "udm-auth-state";

type AuthenticationState = {
  authState: AuthState;
  expiresAt: number | null;
};

export enum AuthState {
  initialization = "initialization",
  guest = "guest",
  member = "member",
  moderator = "moderator",
  administrator = "administrator",
  expired = "expired",
}

const initialState: AuthenticationState = {
  authState: sessionStorage.getItem(UDM_AUTH_STATE_LS_ID)
    ? (String(sessionStorage.getItem(UDM_AUTH_STATE_LS_ID)) as AuthState)
    : AuthState.initialization,
  expiresAt: null,
};

export const authenticationPageReducer = produce<
  AuthenticationState,
  AuthActions
>((state, action) => {
  switch (action.type) {
    case getType(authActions.set.authState):
      state.authState = action.payload;
      sessionStorage.setItem(UDM_AUTH_STATE_LS_ID, action.payload);
      return;
    case getType(authActions.set.expiresAt):
      state.expiresAt = action.payload;
      return;
  }
}, initialState);
