import * as React from "react";
import { SortOrder } from "../../../utils/enums";
import CatalogSelectModal from "../../../components/shared/Modal/CatalogSelectModal";
import { RootState } from "../../../ducks/store";
import {
  getCatalogFilterSearch,
  getCatalogFilterSortOrder,
  getFilteredCatalogCategoryList,
  getCategoryId,
} from "../../../ducks/catalog/selectors";
import { catalogActions } from "../../../ducks/catalog/actions";
import { connect } from "react-redux";

type OwnProps = {
  onClose: () => void;
  isTranslated: boolean;
};

type Props = OwnProps &
  typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps>;

const CategoriesCatalogContainer: React.FC<Props> = ({
  onClose,
  selectedList,
  getCategories,
  addCategory,
  removeCategory,
  removeAllCategories,
  setSortOrder,
  setSearch,
  sortOrder,
  search,
  filteredList,
}) => {
  React.useEffect(() => {
    getCategories();
  }, []);

  const onListItemClick = (id: number) => () => {
    if (selectedList.includes(id)) {
      removeCategory(id);
    } else {
      addCategory(id);
    }
  };

  const onClearClick = () => {
    removeAllCategories();
  };

  const onSortClick = () => {
    if (sortOrder === SortOrder.DESCENDING) {
      setSortOrder(SortOrder.ASCENDING);
    } else {
      setSortOrder(SortOrder.DESCENDING);
    }
  };

  const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  return (
    <CatalogSelectModal
      name="Categories"
      onClose={onClose}
      onListItemClick={onListItemClick}
      list={filteredList}
      onClearSelectClick={onClearClick}
      sortOrder={sortOrder}
      onSortClick={onSortClick}
      search={search}
      onSearchChange={onSearchChange}
    />
  );
};

const mapStateToProps = (state: RootState, props: OwnProps) => ({
  filteredList: getFilteredCatalogCategoryList(state, props),
  selectedList: getCategoryId(state),
  sortOrder: getCatalogFilterSortOrder(state),
  search: getCatalogFilterSearch(state),
});

const mapDispatchToProps = {
  getCategories: catalogActions.getCategories,
  addCategory: catalogActions.filter.addCategory,
  removeCategory: catalogActions.filter.removeCategory,
  removeAllCategories: catalogActions.filter.removeAllCategories,
  setSortOrder: catalogActions.filterUtils.setSortOrder,
  setSearch: catalogActions.filterUtils.setSearch,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CategoriesCatalogContainer);
