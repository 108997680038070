import color from "src/utils/color";
import styled from "styled-components";

const ModalContentWrapper = styled.div`
  border: 1px solid ${color.NEUTRAL_LIGHT};
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
`;

export default ModalContentWrapper;
