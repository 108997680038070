import * as React from "react";
import styled from "styled-components";
import color from "../../utils/color";
import {
  Link,
  RouteComponentProps,
  withRouter,
  NavLink,
} from "react-router-dom";
import Logo from "../../media/svg/udm_logo.svg";
import { RootState } from "../../ducks/store";
import { getNavMenuItems } from "../../ducks/menu/selectors";
import { connect } from "react-redux";
import { authActions } from "../../ducks/authentication/actions";
import NavMenuHeader from "../../components/Menu/NavMenuHeader/NavMenuHeader";
import Button from "../../components/shared/Button/Button";
import { getAuthState } from "../../ducks/authentication/selectors";
import NavBarContent from "../../components/Menu/NavBarContent/NavBarContent";
import PublicNavLinks from "./PublicNavLinks";

const NavBarHeaderContainer = styled.div`
  background-color: ${color.BACKGROUND};
  z-index: 100;
`;

const HeaderContent = styled.div`
  padding: 13px 2rem;
  font-size: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const NavBar = styled.div<{ isLoggedIn: boolean }>`
  position: fixed;
  top: 0;
  width: 100%;
  height: auto;
  z-index: 9999;
  background-color: transparent;
  padding-left: 0;
  box-shadow: ${({ isLoggedIn }) =>
    isLoggedIn ? "0 0.1rem 0.25rem rgba(0, 0, 0, 0.25)" : "none"};
`;

const LogoImg = styled.img`
  height: 36px;
  cursor: pointer;
`;

const LandingPageNavigationContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 3rem;
`;

const PrivateNavLinksWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 5rem;
`;

const FlexCC = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 2rem;

  a.active {
    border-bottom: ${color.PRIMARY} solid 2px;
    box-shadow: 0 5px 5px -4px ${color.PRIMARY};
    margin-bottom: -2px;
  }
`;

const EduAndAnnLink = styled(NavLink)`
  font-size: 0.9rem;
  text-decoration: none;
  color: inherit;

  &:hover {
    border-bottom: ${color.PRIMARY} solid 2px;
    box-shadow: 0 5px 5px -4px ${color.PRIMARY};
    margin-bottom: -2px;
  }
`;

type OwnProps = { isLoggedIn: boolean };

type Props = RouteComponentProps &
  OwnProps &
  ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

const NavBarContainer: React.FC<Props> = ({
  signIn,
  isLoggedIn,
  signOut,
  auth,
}) => (
  <NavBar isLoggedIn={isLoggedIn}>
    <NavBarHeaderContainer>
      <HeaderContent>
        <LandingPageNavigationContainer>
          <Link to="/">
            <LogoImg src={Logo} alt="Understanding Dairy Markets" />
          </Link>
          {!isLoggedIn && <PublicNavLinks />}
        </LandingPageNavigationContainer>
        {isLoggedIn ? (
          <PrivateNavLinksWrapper>
            {!(location.pathname === "/") && <NavBarContent />}
            <FlexCC>
              <EduAndAnnLink to="/educations-and-announcements">
                Announcements &amp; Videos
              </EduAndAnnLink>
              <NavMenuHeader signOut={signOut} auth={auth} />
            </FlexCC>
          </PrivateNavLinksWrapper>
        ) : (
          <Button width={135} height={32} filled onClick={signIn}>
            Sign in
          </Button>
        )}
      </HeaderContent>
    </NavBarHeaderContainer>
  </NavBar>
);

const mapStateToProps = (state: RootState) => ({
  navMenuItems: getNavMenuItems(state),
  auth: getAuthState(state),
});

const mapDispatchToProps = {
  signIn: authActions.signIn,
  signOut: authActions.signOut,
};

const connectedNavBarContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(NavBarContainer);

export default withRouter(connectedNavBarContainer);
