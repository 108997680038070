import { IconButton } from "src/shared/components";
import color from "src/utils/color";
import styled from "styled-components";

const FilterButton = styled(IconButton)`
  border: 1px solid ${color.NEUTRAL_LIGHT};
  border-radius: 0.5rem;
  box-shadow: none;
  padding: 0.5rem 1.5rem;
  transition: transform 0.1s linear;
  font-size: 0.85rem;
  align-items: center;
  column-gap: 0.5rem;

  svg {
    font-size: 0.75rem;
    color: ${color.NEUTRAL};
  }

  &:hover {
    background-color: transparent;
    transform: translateY(0.075rem);
  }
`;

export default FilterButton;
