import styled from "styled-components";
import color from "../../../../utils/color";

const FilterContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  border-bottom: ${color.NEUTRAL} solid 2px;
  padding-bottom: 10px;
`;

export default FilterContent;
