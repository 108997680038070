import styled from "styled-components";
import color from "../../../utils/color";

const SelectSection = styled.div`
  display: flex;
  align-items: baseline;
  padding: 8px 0;
  margin: 30px 0;
  color: ${color.PRIMARY};

  span {
    width: 70px;
  }
`;

export default SelectSection;
