import * as React from "react";
import { SortOrder } from "../../../utils/enums";
import CatalogSelectModal from "../../../components/shared/Modal/CatalogSelectModal";
import { RootState } from "../../../ducks/store";
import {
  getCatalogFilterSearch,
  getCatalogFilterSortOrder,
  getFilteredCatalogMeasuresList,
  getMeasureId,
} from "../../../ducks/catalog/selectors";
import { catalogActions } from "../../../ducks/catalog/actions";
import { connect } from "react-redux";

type OwnProps = {
  onClose: () => void;
  isTranslated: boolean;
};

type Props = OwnProps &
  typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps>;

const MeasuresCatalogContainer: React.FC<Props> = ({
  onClose,
  selectedList,
  getMeasures,
  addMeasure,
  removeMeasure,
  removeAllMeasures,
  setSortOrder,
  setSearch,
  sortOrder,
  search,
  filteredList,
}) => {
  React.useEffect(() => {
    getMeasures();
  }, []);

  const onListItemClick = (id: number) => () => {
    if (selectedList.includes(id)) {
      removeMeasure(id);
    } else {
      addMeasure(id);
    }
  };

  const onClearClick = () => {
    removeAllMeasures();
  };

  const onSortClick = () => {
    if (sortOrder === SortOrder.DESCENDING) {
      setSortOrder(SortOrder.ASCENDING);
    } else {
      setSortOrder(SortOrder.DESCENDING);
    }
  };

  const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  return (
    <CatalogSelectModal
      name="Measures"
      onClose={onClose}
      onListItemClick={onListItemClick}
      list={filteredList}
      onClearSelectClick={onClearClick}
      sortOrder={sortOrder}
      onSortClick={onSortClick}
      search={search}
      onSearchChange={onSearchChange}
    />
  );
};

const mapStateToProps = (state: RootState, props: OwnProps) => ({
  filteredList: getFilteredCatalogMeasuresList(state, props),
  selectedList: getMeasureId(state),
  sortOrder: getCatalogFilterSortOrder(state),
  search: getCatalogFilterSearch(state),
});

const mapDispatchToProps = {
  getMeasures: catalogActions.getMeasures,
  addMeasure: catalogActions.filter.addMeasure,
  removeMeasure: catalogActions.filter.removeMeasure,
  removeAllMeasures: catalogActions.filter.removeAllMeasures,
  setSortOrder: catalogActions.filterUtils.setSortOrder,
  setSearch: catalogActions.filterUtils.setSearch,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MeasuresCatalogContainer);
