import * as React from "react";
import { CustomRouteProps } from "./router.types";
import { Redirect, Route } from "react-router-dom";
import NavBarContainer from "src/containers/NavBarContainer/NavBarContainer";
import { Container, ContainerWrapper, Content, Root } from "./layout";

export default function PrivateRoute({
  component: Component,
  isAuthenticated,
  ...rest
}: CustomRouteProps) {
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          <Root>
            <NavBarContainer isLoggedIn={isAuthenticated} />
            <ContainerWrapper>
              <Container>
                <Content>
                  <Component {...props} />
                </Content>
              </Container>
            </ContainerWrapper>
          </Root>
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );
}
