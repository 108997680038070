import * as React from "react";
import { Spinner } from "src/components/shared/Spinner/Spinner";
import styled from "styled-components";

const Root = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2.5rem 0;
`;

export default function EduAndAnnSkeleton() {
  return (
    <Root>
      <Spinner />
    </Root>
  );
}
