import * as Azure from "@azure/storage-blob";
import { all, call, put, takeLatest } from "redux-saga/effects";
import { ActionType, getType } from "typesafe-actions";
import { educationsAndAnnouncementsActions } from "./actions";
import { AxiosResponse } from "axios";
import { Announcement } from "src/scenes/EducationsAndAnnouncements/utils/announcements.types";
import adminAPI from "src/services/adminServices";
import { toast } from "react-toastify";
import {
  EducationalVideo,
  EEducationalVideoRole,
} from "src/scenes/EducationsAndAnnouncements/utils/educationalVideos.types";

async function storeBlockBlob(
  blockBlobClient: Azure.BlockBlobClient,
  file: File
) {
  return await blockBlobClient.upload(file, file.size);
}

function* fetchAnnouncements() {
  try {
    const response: AxiosResponse<Array<Announcement>> = yield call(
      adminAPI.fetchAnnouncements
    );
    yield put(
      educationsAndAnnouncementsActions.announcements.populate(response.data)
    );
  } catch (error) {
    toast.error("Failed to fetch announcements.");
    console.error("Saga", error);
  }
}

function* createAnnouncement(
  action: ActionType<
    typeof educationsAndAnnouncementsActions.announcements.create
  >
) {
  try {
    yield call(adminAPI.createAnnouncement, action.payload);
  } catch (error) {
    toast.error("Failed to create announcement.");
    console.error("Saga", { error, action });
  } finally {
    yield call(fetchAnnouncements);
    action.meta();
  }
}

function* deleteAnnouncement(
  action: ActionType<
    typeof educationsAndAnnouncementsActions.announcements.delete
  >
) {
  try {
    yield call(adminAPI.deleteAnnouncement, action.payload);
    yield put(
      educationsAndAnnouncementsActions.announcements.remove(action.payload)
    );
  } catch (error) {
    toast.error("Failed to delete announcement.");
    console.error("Saga", { error, action });
    yield put(
      educationsAndAnnouncementsActions.announcements.add(action.payload)
    );
  }
}

function* editAnnouncement(
  action: ActionType<
    typeof educationsAndAnnouncementsActions.announcements.edit
  >
) {
  try {
    yield call(adminAPI.editAnnouncement, action.payload);
    yield put(
      educationsAndAnnouncementsActions.announcements.update(action.payload)
    );
  } catch (error) {
    toast.error("Failed to edit announcement.");
    console.error("Saga", { error, action });
    yield call(fetchAnnouncements);
  } finally {
    action.meta();
  }
}

function* fetchEducationalVideos() {
  try {
    const response: AxiosResponse<Array<EducationalVideo>> = yield call(
      adminAPI.fetchEducationalVideos
    );
    yield put(
      educationsAndAnnouncementsActions.educationalVideos.populate(
        response.data
      )
    );
  } catch (error) {
    toast.error("Failed to fetch educational videos.");
    console.error("Saga", error);
  }
}

function* uploadEducationalVideo(params: {
  videoFile: File;
  credential: Azure.AnonymousCredential;
  uploadUri: string;
  guid: string;
}) {
  try {
    const blockBlobClient = new Azure.BlockBlobClient(
      params.uploadUri,
      params.credential
    );
    yield storeBlockBlob(blockBlobClient, params.videoFile);
    yield call(adminAPI.completeUploadEducationalVideo, params.guid);
  } catch (error) {
    console.error("Saga", { error, params });
    toast.error("Failed to upload video.");
  }
}

function* uploadEducationalVideoThumbnail(params: {
  thumbnailFile: File;
  credential: Azure.AnonymousCredential;
  videoGuid: string;
}) {
  try {
    const response: AxiosResponse<{
      guid: string;
      uploadUri: string;
    }> = yield call(adminAPI.createEducationalVideoThumbnail, {
      videoId: params.videoGuid,
      fileName: params.thumbnailFile.name,
    });
    const blockBlobClient = new Azure.BlockBlobClient(
      response.data.uploadUri,
      params.credential
    );
    yield storeBlockBlob(blockBlobClient, params.thumbnailFile);
    yield call(
      adminAPI.completeUploadEducationalVideoThumbnail,
      response.data.guid
    );
  } catch (error) {
    console.error("Saga", { error, params });
    toast.error("Failed to upload thumbnail.");
  }
}

function* createEducationalVideo(
  action: ActionType<
    typeof educationsAndAnnouncementsActions.educationalVideos.createVideo
  >
) {
  try {
    const response: AxiosResponse<{
      guid: string;
      uploadUri: string;
    }> = yield call(adminAPI.createEducationalVideo, {
      fileName: action.payload.file.name,
      payload: {
        category: action.payload.category,
        description: action.payload.description,
        duration: action.payload.duration,
        roles: [EEducationalVideoRole.None],
        title: action.payload.title,
        videoDate: new Date().toISOString(),
      },
    });
    const { guid, uploadUri } = response.data;
    const credential = new Azure.AnonymousCredential();
    yield all([
      call(uploadEducationalVideo, {
        credential,
        guid,
        uploadUri,
        videoFile: action.payload.file,
      }),
      call(uploadEducationalVideoThumbnail, {
        credential,
        thumbnailFile: action.payload.thumbnail,
        videoGuid: guid,
      }),
    ]);
  } catch (error) {
    toast.error("Failed to create educational video.");
    console.error("Saga", { error, action });
  } finally {
    yield call(fetchEducationalVideos);
    action.meta();
  }
}

function* deleteEducationalVideo(
  action: ActionType<
    typeof educationsAndAnnouncementsActions.educationalVideos.deleteVideo
  >
) {
  try {
    yield call(adminAPI.deleteEducationalVideo, action.payload);
    yield put(
      educationsAndAnnouncementsActions.educationalVideos.removeVideo(
        action.payload
      )
    );
  } catch (error) {
    toast.error("Failed to delete educational video.");
    console.error("Saga", { error, action });
  }
}

function* deleteEducationVideoThumbnail(videoId: string) {
  try {
    const response: AxiosResponse = yield call(
      adminAPI.getEducationVideoThumbnail,
      videoId
    );
    yield call(adminAPI.deleteEducationVideoThumbnail, response.data.id);
  } catch (error) {
    toast.error("Failed to delete video thumbnail.");
    console.error("Saga", { error });
  }
}

function* editEducationalVideo(
  action: ActionType<
    typeof educationsAndAnnouncementsActions.educationalVideos.editVideo
  >
) {
  const credential = new Azure.AnonymousCredential();
  try {
    yield call(adminAPI.editEducationalVideo, action.payload.video);
    if (action.payload.thumbnail) {
      yield all([
        uploadEducationalVideoThumbnail({
          credential,
          thumbnailFile: action.payload.thumbnail,
          videoGuid: action.payload.video.id,
        }),
        deleteEducationVideoThumbnail(action.payload.video.id),
      ]);
    }
    const thumbnailResponse: AxiosResponse<{
      id: string;
      videoId: string;
      fileName: string;
      filePath: string;
      uploadUri: string;
      completed: boolean;
      created: string;
      deleted: boolean;
    }> = yield call(
      adminAPI.getEducationVideoThumbnail,
      action.payload.video.id
    );
    yield put(
      educationsAndAnnouncementsActions.educationalVideos.updateVideo({
        ...action.payload.video,
        thumbnailUri: thumbnailResponse.data.uploadUri,
      })
    );
  } catch (error) {
    toast.error("Failed to edit educational video.");
    console.error("Saga", { error, action });
  } finally {
    action.meta();
  }
}

export default function* educationsAndAnnouncementsSagaWatcher() {
  yield all([
    takeLatest(
      getType(educationsAndAnnouncementsActions.announcements.fetch),
      fetchAnnouncements
    ),
    takeLatest(
      getType(educationsAndAnnouncementsActions.announcements.create),
      createAnnouncement
    ),
    takeLatest(
      getType(educationsAndAnnouncementsActions.announcements.delete),
      deleteAnnouncement
    ),
    takeLatest(
      getType(educationsAndAnnouncementsActions.announcements.edit),
      editAnnouncement
    ),
    takeLatest(
      getType(educationsAndAnnouncementsActions.educationalVideos.fetch),
      fetchEducationalVideos
    ),
    takeLatest(
      getType(educationsAndAnnouncementsActions.educationalVideos.createVideo),
      createEducationalVideo
    ),
    takeLatest(
      getType(educationsAndAnnouncementsActions.educationalVideos.deleteVideo),
      deleteEducationalVideo
    ),
    takeLatest(
      getType(educationsAndAnnouncementsActions.educationalVideos.editVideo),
      editEducationalVideo
    ),
  ]);
}
